import React from 'react'
import amazonLogo from '../../images/vendor/amazon.png'
import amazonLogoLight from '../../images/vendor/amazon-light.png'
import itunesLogo from '../../images/vendor/itunes.png'
import itunesLogoLight from '../../images/vendor/itunes-light.png'
import skyLogo from '../../images/vendor/skystore.png'
import googlePlayLogo from '../../images/vendor/googleplay.png'
import googlePlayLogoLight from '../../images/vendor/googleplay-light.png'
import appleTVLogo from '../../images/vendor/apple-tv.png'
import appleTVLogoLight from '../../images/vendor/apple-tv-light.png'

class Vendor extends React.Component {
  render () {
    let amazonImg = this.props.className === 'c-vendor--inpage' ? amazonLogoLight : amazonLogo
    let itunesImg = this.props.className === 'c-vendor--inpage' ? itunesLogoLight : itunesLogo
    let googlePlayImg = this.props.className === 'c-vendor--inpage' ? googlePlayLogoLight : googlePlayLogo
    let appleTVImg = this.props.className === 'c-vendor--inpage' ? appleTVLogoLight : appleTVLogo
    return (
      <div className={`c-vendor ${ this.props.className }`}>
        <div className="container">
          <div className="row">
            <div className="c-vendor__wrapper">
              <strong className="c-vendor__title">Buy now</strong>
              <ul className="c-vendor__list">
                <li className="c-vendor__item">
                  <a href="https://amzn.to/2LSxQIq" className="c-vendor__link" target="_blank" data-vendor="Amazon">
                    <img src={amazonImg} alt="Buy from Amazon" className="c-vendor__img" />
                  </a>
                </li>
                <li className="c-vendor__item">
                  <a href="https://apple.co/2HyBwcQ" className="c-vendor__link" target="_blank" data-vendor="Apple iTunes">
                    <img src={itunesImg} alt="Buy from iTunes" className="c-vendor__img" />
                  </a>
                </li>
                <li className="c-vendor__item">
                  <a href="http://bit.ly/2VJE0KH" className="c-vendor__link" target="_blank" data-vendor="Sky Store">
                    <img src={skyLogo} alt="Buy from Sky Store" className="c-vendor__img" />
                  </a>
                </li>
                <li className="c-vendor__item">
                  <a href="http://bit.ly/2GADZEj" className="c-vendor__link" target="_blank" data-vendor="Google Play">
                    <img src={googlePlayImg} alt="Buy from Google Play" className="c-vendor__img" />
                  </a>
                </li>
                <li className="c-vendor__item">
                  <a href="https://apple.co/2Y1vYD6" className="c-vendor__link" target="_blank" data-vendor="Apple TV">
                    <img src={appleTVImg} alt="Buy from Apple TV" className="c-vendor__img c-vendor__img--large" />
                  </a>
                </li>
                <li className="c-vendor__item c-vendor__item--description">Also available at supermarkets and retailers nationwide</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Vendor
